<template>
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }">
		<template #title>
			<h6 class="font-semibold m-0">{{$t('server.list.title')}}</h6>
		</template>
		<a-button type="primary" slot="extra" size="small" @click="createServer">{{$t('server.btnCreate')}}</a-button>
		<CardProcess @serverReload="getData" @resizeServerReload="getData"/>
		<a-row :gutter="[24, 24]" v-if="!fetchListing">
			<a-col :span="24" v-for="row in rows">
				<a-card :bordered="false" class="card-billing-info">
					<a-badge v-if="row.status === SERVER_ACTIVE" status="success" />
					<a-badge v-else-if="row.status === SERVER_ERROR" status="error" />
					<a-badge v-else status="default" />
					<div class="col-info">
						<a-descriptions :title="row.name" :column="1">
							<a-descriptions-item label="IP LAN">
								{{ 
									_.map(_.get(row.network, 'private_ips', []), function (item) {
										return item.addr;
									}).join(', ')
								}}
							</a-descriptions-item>
							<a-descriptions-item v-if="!_.isEmpty(row.network.public_ips)" label="IP WAN">
								{{ 
									_.map(_.get(row.network, 'public_ips', []), function (item) {
										return item.addr;
									}).join(', ')
								}}
							</a-descriptions-item>
							<a-descriptions-item :label="$t('config')">
								{{row.config.vcpus}} Core CPU / {{row.config.ram}} {{STORAGE_UNIT}} RAM / {{row.config.disk}} {{STORAGE_UNIT}} {{row.config.disk_type.toUpperCase()}}
							</a-descriptions-item>
							<a-descriptions-item :label="$t('created')">
								{{row.created | moment("HH:mm DD-MM-YYYY")}}
							</a-descriptions-item>
						</a-descriptions>
					</div>
					<div class="col-action">
						<a-dropdown placement="bottomRight">
							<a-menu slot="overlay" @click="selectAction">
								<a-menu-item key="info"  :value="{id: row.id}">{{$t('info')}}</a-menu-item>
								<a-menu-item key="resize"  :value="{id: row.id}">{{$t('server.list.resize')}}</a-menu-item>
								<a-menu-item key="resetPassword"  :value="{id: row.id}">Reset password</a-menu-item>
								<!-- <a-menu-item key="rebuild"  :value="{id: row.id}">{{$t('server.rebuild.title')}}</a-menu-item> -->
								<!-- <a-menu-item key="logs"  :value="{id: row.id}">{{$t('server.list.log')}}</a-menu-item> -->
								<a-menu-item key="delete"  :value="{id: row.id}"><a-badge status="error" /> {{$t('server.list.btnDelete')}}</a-menu-item>
							</a-menu>
							<a-button size="small"> {{$t('action')}} <a-icon type="down" /> </a-button>
						</a-dropdown>
					</div>
				</a-card>
			</a-col>
			<a-empty v-if="_.isEmpty(rows)" />
		</a-row>
		<a-spin v-else tip="Loading..."></a-spin>
	</a-card>
</template>

<script>
	import listing from "@/services/server/listing"
	import {SERVER_ACTIVE, SERVER_ERROR, STORAGE_UNIT} from "@/constant"
	import CardProcess from "@/components/Cards/CardProcess"

	export default ({
		components: {
			CardProcess,
		},
		data() {
			return {
				fetchListing: true,
				rows: [],
				SERVER_ACTIVE,
				SERVER_ERROR,
				STORAGE_UNIT
			}
		},
		created() {
			this.getData()
		},
		methods: {
			createServer() {
				this.$router.push({name: 'server.create'})
			},
			getData() {
				this.fetchListing = true
				listing()
				.then(res=> {
					this.fetchListing = false
					if (this._.isArray(res)) {
						this.rows = res
					}
				})
			},
			selectAction(e) {
				const value = e.item.value
				switch(e.key) {
					case 'info':
						this.$router.push({name: 'server.detail', params: {id: value.id}})
					break;
					case 'resize':
						this.$router.push({name: 'server.detail', params: {id: value.id}, query:{action: 'resize'}})
					break;
					case 'resetPassword':
						this.$router.push({name: 'server.detail', params: {id: value.id}, query:{tab: 'reset-password'}})
					break;
					case 'rebuild':
						this.$router.push({name: 'server.detail', params: {id: value.id}, query:{action: 'rebuild'}})
					break;
					case 'logs':
						this.$router.push({name: 'server.detail', params: {id: value.id}, query:{action: 'logs'}})
					break;
					case 'delete':
						this.$router.push({name: 'server.detail', params: {id: value.id}, query:{tab: 'delete'}})
					break;
				}
			}
		}
	})

</script>